<template>
  <div class="sign-up bg-bgsection d-flex flex-column">
    <LoginHeader>
      <p
        v-if="$device.isMobile"
        class="m-0 mr-3 text-secondary font-weight-bold"
        @click="onClickLogin"
      >
        {{ $t('登入') }}
      </p>
      <div v-else class="web-btn-wrapper">
        <b-button variant="white" size="lg" @click.stop="onClickLogin">
          {{ $t('登入') }}
        </b-button>
      </div>
    </LoginHeader>

    <div class="sign-up-wrapper scroll-bar">
      <div
        class="sign-up-container d-flex align-items-center justify-content-center flex-column mx-auto px-3 px-md-0"
      >
        <h3 class="mb-4 mb-md-5">{{ $t('免費註冊') }}</h3>

        <i18n path="terms" tag="p" class="mb-4 mb-md-5 text-center">
          <router-link
            :to="{name: 'terms-of-service'}"
            class="underline text-secondary"
          >
            {{ $t('服務條款') }}
          </router-link>
          <router-link
            :to="{name: 'terms-of-service'}"
            class="underline text-secondary"
          >
            {{ $t('隱私政策') }}
          </router-link>
        </i18n>

        <div class="sub-title mb-3 text-center" style="width: 280px">
          {{ $t('快速註冊 / 登入方式') }}
        </div>
        <div
          class="oauth-login-wrapper d-flex justify-content-between w-100 mb-4 mb-md-5"
        >
          <GoogleLoginButton v-if="loginMethods.includes('google')">
            <div class="oauth-login-btn">
              <img :src="GoogleIcon" />
            </div>
          </GoogleLoginButton>
          <AppleLoginButton v-if="loginMethods.includes('apple')" class="mx-3">
            <div class="oauth-login-btn">
              <img :src="AppleIcon" />
            </div>
          </AppleLoginButton>
          <LineLoginButton v-if="loginMethods.includes('line')">
            <div class="oauth-login-btn">
              <img :src="LineIcon" />
            </div>
          </LineLoginButton>
        </div>

        <div class="sub-title mb-3 text-center" style="width: 244px">
          {{ $t('訪客帳號註冊') }}
        </div>
        <b-button
          variant="white"
          size="lg"
          class="w-100 font-weight-bold"
          @click.stop="onClickGuestSignUp"
        >
          {{ $t('訪客註冊') }}
        </b-button>

        <template v-if="!$device.isMobile">
          <hr class="divider my-5 w-100" />
          <p class="text-gray text-center font-weight-bold">
            {{ $t('已經持有訪客帳號了嗎？') }}
          </p>
          <b-button
            variant="white"
            size="lg"
            class="w-100 font-weight-bold"
            @click.stop="onClickLogin"
          >
            {{ $t('登入') }}
          </b-button>
        </template>
      </div>
    </div>

    <CopyrightFooter mode="primary" />
  </div>
</template>

<script>
import {loginMethods} from '@/constant/env.js';
import CopyrightFooter from '@/components/Base/CopyrightFooter.vue';
import LoginHeader from '@/components/Base/LoginHeader.vue';
import GoogleLoginButton from '@/components/Login/GoogleLoginButton.vue';
import AppleLoginButton from '@/components/Login/AppleLoginButton.vue';
import LineLoginButton from '@/components/Login/LineLoginButton.vue';
import GoogleIcon from '@/assets/img/login/Google.png';
import AppleIcon from '@/assets/img/login/Apple.png';
import LineIcon from '@/assets/img/login/Line.png';
// import i18n from '@/i18n';

export default {
  components: {
    LoginHeader,
    CopyrightFooter,
    GoogleLoginButton,
    AppleLoginButton,
    LineLoginButton,
    // i18n,
  },
  data() {
    return {
      GoogleIcon,
      AppleIcon,
      LineIcon,
      loginMethods,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.env.isLoading;
    },
  },
  methods: {
    async onClickGuestSignUp() {
      if (this.isLoading) {
        return;
      }
      try {
        this.$store.commit('env/setIsLoading', true);
        await this.$store.dispatch('user/thirdPartyLogin', {
          platform: 'guest',
        });
        await this.$store.dispatch('user/loginWithJwt');
        this.$router.push({name: 'guest-login-detail'});
      } catch (error) {
        this.$Message.error(this.$t('登入失敗'));
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
    onClickLogin() {
      if (this.isLoading) {
        return;
      }
      this.$router.push({name: 'login'});
    },
    getRouterLink() {
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-up-wrapper {
  overflow: overlay;
  height: calc(
    100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 56px - 48px
  );
  @media screen and (min-width: 768px) {
    height: calc(
      100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 68px -
        48px
    );
  }
}
.sign-up-container {
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-bottom: 24px;

  @media screen and (min-width: 768px) {
    height: min-content;
    min-height: -webkit-fill-available;
    max-width: 370px;
  }
  h3 {
    font-size: 28px;
    line-height: 44px;
  }
  .oauth-login-btn {
    border: 1px solid $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: white;
    border-radius: $rounded-sm;
    padding: 8px 0;
    img {
      width: 32px;
    }
  }

  .sub-title {
    line-height: 28px;
    background: rgba($primary, $alpha: 0.1);
    border-radius: 14px;
    color: $font-grayscale-1;
  }

  button {
    border-color: $primary;
  }
}
</style>
